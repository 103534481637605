import React from 'react'
import Layout from '../../../components/Layout/layout'
import SEO from '../../../components/SEO/seo'
import { LoginPage } from '../../../components/auth'
const Login = () => (
	<Layout>
		<SEO title='Login' />
		<LoginPage language='EN'/>
	</Layout>
)

export default Login
